<template>
  <div class="mw-1200">
    <el-row class="mmb-15 mtext-center ttext-center">
      <el-col
        class="page-title-75 mb-10"
        :xs="{ span: 14, offset: 5 }"
        :sm="{ span: 14, offset: 5 }"
        :md="{ span: 11, offset: 1 }"
        :lg="{ span: 11, offset: 1 }"
        :xl="{ span: 12, offset: 1 }"
      >
        The New <span class="pink-text">Streaming</span> Standard.
      </el-col>

      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 10, offset: 7 }"
        :md="{ span: 10, offset: 0 }"
        :lg="{ span: 10, offset: 0 }"
        :xl="{ span: 10, offset: 0 }"
      >
        <el-row justify="space-around">
          <el-col :xs="{ span: 18, offset: 0 }" class="paragraph-text-24 mb-8">
            <span class="gilroy-bold">Sign up</span> for our newsletter to stay
            up to date & get early access to FIRST.
          </el-col>
          <el-col
            :span="21"
            class="hidden-767-and-up signup-mobile"
            :class="formClass"
          >
            <input
              type="text"
              placeholder="E-mail address.."
              class="signup-input-mobile"
              v-model="signupEmail"
              :readonly="subscribeStatus"
            />
            <button
              @click="handleSignup"
              class="btn-signup-mobile"
              :disabled="subscribeStatus"
            >
              <img src="/img/check.svg" style="vertical-align: middle" alt="" />
            </button>
          </el-col>

          <el-col
            :xs="{ span: 21, offset: 0 }"
            class="paragraph-text-19 mb-5 hidden-767-and-down"
          >
            <form
              @submit.prevent="handleSignup"
              class="signup"
              :class="formClass"
            >
              <input
                class="signup-input"
                placeholder="E-mail address.."
                type="text"
                name="signup-email"
                id="signup-email"
                v-model="signupEmail"
                :readonly="subscribeStatus"
              />
              <button
                :disabled="subscribeStatus"
                class="btn-signup hidden-xs-only gilroy-bold"
              >
                Sign up!
              </button>
            </form>
          </el-col>
          <el-col class="email-disclaimer mb-5 hidden-xs-only">
            We will only use your e-mail address for important updates.
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row justify="space-around" class="mb-3 mmb-10">
      <el-col
        :xs="{ span: 2, offset: 0 }"
        :sm="{ span: 1, offset: 0 }"
        :md="{ span: 1, offset: 0 }"
        :lg="{ span: 1, offset: 0 }"
        :xl="{ span: 1, offset: 0 }"
      >
        <img src="/img/footer-logo.svg" alt="" />
      </el-col>
    </el-row>
    <el-row justify="space-around" class="mb-3 mmb-10">
      <el-col
        :xs="{ span: 22, offset: 0 }"
        :sm="{ span: 10, offset: 0 }"
        :md="{ span: 7, offset: 0 }"
        :lg="{ span: 6, offset: 0 }"
        :xl="{ span: 6, offset: 0 }"
      >
        <el-row
          justify="space-around"
          class="text-center gilroy-bold footer-links"
        >
          <el-col :span="8"
            ><a href="/downloads/FIRST_Pitchdeck.pdf" target="_blank"
              >Pitchdeck</a
            ></el-col
          >
          <el-col :span="8"
            ><a href="/downloads/First_Press_Kit_2021.zip" download
              >Press Kit</a
            ></el-col
          >
          <el-col :span="8"
            ><a href="/downloads/FIRST__Tokenomics.pdf" target="_blank"
              >Tokenomics</a
            ></el-col
          >
        </el-row>
      </el-col>
    </el-row>
    <el-row id="rights" class="text-center mb-3">
      <el-col>2021 &copy; All Rights Reserved</el-col>
    </el-row>
    <el-row class="text-center mb-3 mmb-15">
      <el-col id="terms">
        <a
          target="_blank"
          href="https://www.termsfeed.com/live/8be79d04-96e7-4454-a4a6-aa91c7729e21"
          >Terms & Conditions</a
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import { VueMailchimpEmailSignupForm } from "vue-mailchimp-email-signup-form";

export default {
  //  components: {
  //     VueMailchimpEmailSignupForm,
  //   },
  data() {
    return {
      signupEmail: "",
      subscribeStatus: null,
      formClass: null,
    };
  },
  methods: {
    handleSignup() {
      this.axios
        .get(
          "https://firstmailchimp.trone.nl/weatherforecast?email=" +
            this.signupEmail +
            ""
        )
        .then((response) => {
          // console.log(response);
          if (response.data == "Error while signing up") {
            this.formClass = "fail";
            this.signupEmail = "Something went wrong, please try again later.";
          } else {
            this.subscribeStatus = true;
            this.formClass = "success";
            this.signupEmail = "Thanks for signing up.";
          }
        });
    },
  },
};
</script>

<style lang="css" scoped>
/* @media only screen and (min-width: 992px) {
  .footer-bg {
    border-radius: 7%/35%;
  }
} */

#Whitepaper-col {
  opacity: 0.5;
}

.signup {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 36px;
  height: 70%;
  padding: 2% 2% 2% 5%;
  width: 100%;
}

.signup-input {
  border-radius: 36px;
  border: none;
  background-color: transparent;
  flex-grow: 2;
  font-size: 100%;
  color: #191234;
}

.signup-input:focus {
  outline: none;
}

.btn-signup {
  background-color: #191234;
  border-radius: 36px;
  border: none;
  color: #fff;
  font-size: 100%;
  padding: 2% 3%;
}

.btn-signup:active,
.btn-signup:hover {
  background-color: #191234;
  color: #fff;
  cursor: pointer;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #191234;
  opacity: 0.57; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #191234;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #191234;
}

.email-disclaimer {
  color: #c6c1d7;
  font-size: 12px;
  font-family: "GilroyMedium";
}

/** New footer */
.footer-links .el-col a {
  text-decoration: none;
  color: #191234;
  font-size: 15px;
}

#terms a,
#rights {
  color: #191234;
  font-size: 12px;
  font-family: "GilroyMedium";
}

.hidden-767-and-up {
  display: none !important;
}

.hidden-767-and-down {
  display: flex !important;
}

.dmb-20 {
  margin-bottom: 20%;
}

.dmb-6 {
  margin-bottom: 6%;
}

.signup.success input,
.signup-mobile.success input {
  color: green;
}

.signup.fail input,
.signup-mobile.fail input {
  color: red;
}

@media only screen and (max-width: 1199px) {
  .footer-bg {
    padding: 2% 0;
    background: url("/img/footer-bg.png") no-repeat;
    background-size: 100%;
    /* border-radius: 6%/24%; */
    background-size: cover;
  }

  .footer-category-text {
    font-size: 16px;
  }

  .pb-h {
    padding-bottom: 1%;
  }

  .pb-h a,
  .community-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-bg {
    padding: 2% 0;
    background: url("/img/footer-bg.png") no-repeat;
    background-size: 100%;
    /* border-radius: 6%/24%; */
    background-size: cover;
  }

  .footer-category-text {
    font-size: 15px;
  }

  .pb-h {
    padding-bottom: 1%;
  }

  .pb-h a,
  .community-text {
    font-size: 13px;
  }

  .mmb-15 {
    margin-bottom: 10%;
  }

  .signup{
    height:45px;
  }

  .btn-signup{
    width: 100px;
    margin-right:1.7%;
  }
}

@media only screen and (max-width: 767px) {
  .pb-h {
    padding-top: 2.5% !important;
  }

  input {
    font-size: 20px;
  }

  .footer-block-2 {
    margin-top: 5% !important;
  }

  .footer-block-3 {
    margin-top: 2% !important;
  }

  .hidden-767-and-up {
    display: flex !important;
  }

  .hidden-767-and-down {
    display: none !important;
  }

  .signup {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 36px;
    height: 72px;
    padding: 0 5% 0 10%;
  }

  .signup-input {
    border-radius: 36px;
    border: none;
    background-color: transparent;
    flex-grow: 2;
    font-size: 20px;
    color: #191234;
  }

  .signup-input:focus {
    outline: none;
  }

  .btn-signup {
    background-color: #191234;
    border-radius: 50%;
    border: none;
    color: #fff;
    height: 48px;
    width: 48px;
    font-size: 100%;
    margin: auto 0;
  }

  .btn-signup img {
    vertical-align: middle;
  }

  .signup-mobile {
    display: flex;
    width: 100%;
    background-color: white;
    border-radius: 50px;
    height: 100%;
    padding: 4%;
  }

  .signup-input-mobile {
    flex-grow: 2;
    border: none;
    margin-left: 5%;
  }

  .signup-input-mobile:active,
  .signup-input-mobile:focus {
    border: none;
    outline: none;
  }

  .btn-signup-mobile:active,
  .btn-signup-mobile:hover {
    background-color: #191234;
    color: #fff;
    cursor: pointer;
  }

  .btn-signup-mobile {
    background-color: #191234;
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    margin-left: -7%;
  }

  .footer-bg {
    padding: 10% 0;
    background: url("/img/footer-bg-mobile.png") no-repeat;
    background-size: 100%;
    /* border-radius: 12%/28%; */
    background-color: #191234;
    background-size: cover;
  }

  .footer-category-text {
    font-size: 18px;
  }

  .pb-h {
    padding-bottom: 2%;
  }

  .pb-h a,
  .community-text {
    font-size: 18px;
  }

  .footer-socials {
    width: 36%;
  }

  .comm-cat {
    margin-bottom: 7% !important;
  }

  .mjc {
    justify-content: center;
  }

  .mmb-15 {
    margin-bottom: 15%;
  }

  .mmt-15 {
    margin-top: 15%;
  }

  .mmt-5 {
    margin-top: 5%;
  }

  .footer-hr {
    width: 100%;
    margin-bottom: 10%;
  }

  .dmb-20 {
    margin-bottom: 5%;
  }

  .dmb-6 {
    margin-bottom: 0%;
  }
}
</style>