<template>
  <!-- <div class="background-logo"></div> -->
  <Nav />
  <br />
  <router-view />
</template>

<script>
import Nav from "@/components/Nav";

export default {
  name: "App",
  components: {
    Nav,
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 100px 0 0 0;
  background-color: #f3f1f2;
}

#nfts,
#stream,
#originals,
#about {
  padding-top: 100px;
  margin-top: -100px;
}

.mw-1200 {
  margin: 0 auto;
  max-width: 1200px;
}

@font-face {
  font-family: GilroyRegular;
  src: url(assets/fonts/Gilroy-Regular.ttf);
}
.gilroy-regular {
  font-family: "GilroyRegular" !important;
}

@font-face {
  font-family: GilroyBold;
  src: url(assets/fonts/Gilroy-Bold.ttf);
}
.gilroy-bold {
  font-family: "GilroyBold" !important;
}

@font-face {
  font-family: GilroySemiBold;
  src: url(assets/fonts/Gilroy-SemiBold.ttf);
}
.gilroy-semibold {
  font-family: "GilroySemiBold" !important;
}

@font-face {
  font-family: GilroyMedium;
  src: url(assets/fonts/Gilroy-Medium.ttf);
}
.gilroy-medium {
  font-family: "GilroyMedium" !important;
}

@font-face {
  font-family: GilroyExtraBold;
  src: url(assets/fonts/Gilroy-ExtraBold.ttf);
}
.gilroy-extrabold {
  font-family: "GilroyExtraBold" !important;
}

.background-logo {
  background: url("../public/img/background-logo-desktop.png") no-repeat;
  position: absolute;
  height: 150vh;
  width: 100%;
  /* left: -10%; */
  top: 0;
  z-index: -1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #191234;
}

.pink-text {
  color: #f54a56;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.hiddenbr {
  display: none;
}

.disabled-link {
  pointer-events: none;
}

/* TITLES & PARAGRAPHS */
.page-title-82,
.page-title-80,
.page-title-75,
.page-title-70 {
  font-family: "GilroyBold";
}

.page-title-82 {
  font-size: 82px;
  line-height: 112px;
}

.page-title-80 {
  font-size: 80px;
  line-height: 86px;
}

.page-title-75 {
  font-size: 75px;
  line-height: 86px;
}

.page-title-70 {
  font-size: 70px;
  line-height: 84px;
}

.paragraph-title-34,
.paragraph-title-28 {
  font-family: "GilroyExtraBold";
}

.paragraph-title-34 {
  font-size: 34px;
  line-height: 43px;
}

.paragraph-title-28 {
  font-size: 28px;
  line-height: 43px;
}

.paragraph-text-26,
.paragraph-text-24,
.paragraph-text-21,
.paragraph-text-19 {
  font-family: "GilroyMedium";
}

.paragraph-text-28,
.paragraph-text-26,
.paragraph-text-24 {
  line-height: 43px;
}

.paragraph-text-26 {
  font-size: 26px;
}

.paragraph-text-24 {
  font-size: 24px;
}

.paragraph-text-21 {
  font-size: 21px;
  line-height: 42px;
}

.paragraph-text-19 {
  font-size: 19px;
  line-height: 34px;
}

.fz-25 {
  font-size: 25px !important;
  line-height: 35px !important;
}

.fz-21 {
  font-size: 21px !important;
  line-height: 35px !important;
}

/* MARGIN CLASSES */
.mb-2 {
  margin-bottom: 2%;
}

.mb-3 {
  margin-bottom: 3%;
}

.mb-5 {
  margin-bottom: 5%;
}

.mb-8 {
  margin-bottom: 8%;
}

.mb-10 {
  margin-bottom: 10%;
}

.mb-15 {
  margin-bottom: 15%;
}

.mb-20 {
  margin-bottom: 20%;
}

.hidden-991-and-up {
  display: none;
}

.hidden-991-and-down {
  display: block;
}

@media only screen and (max-width: 1400px) {
  /* TITLES & PARAGRAPHS */
  .page-title-82 {
    font-size: 77px;
    line-height: 102px;
  }

  .page-title-80 {
    font-size: 75px;
    line-height: 83px;
  }

  .page-title-75 {
    font-size: 70px;
    line-height: 83px;
  }

  .page-title-70 {
    font-size: 65px;
    line-height: 81px;
  }

  .paragraph-title-34 {
    font-size: 32px;
    line-height: 42px;
  }

  .paragraph-title-28 {
    font-size: 27px;
    line-height: 40px;
  }

  .paragraph-text-28,
  .paragraph-text-26,
  .paragraph-text-24 {
    line-height: 40px;
  }

  .paragraph-text-26 {
    font-size: 24px;
  }

  .paragraph-text-24 {
    font-size: 22px;
  }

  .paragraph-text-21 {
    font-size: 19px;
    line-height: 39px;
  }

  .paragraph-text-19 {
    font-size: 18px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 1199px) {
  /* TITLES & PARAGRAPHS */
  .page-title-82 {
    font-size: 63px;
    line-height: 92px;
  }

  .page-title-80 {
    font-size: 70px;
    line-height: 80px;
  }

  .page-title-75 {
    font-size: 65px;
    line-height: 79px;
  }

  .page-title-70 {
    font-size: 60px;
    line-height: 78px;
  }

  .paragraph-title-34 {
    font-size: 30px;
    line-height: 40px;
  }

  .paragraph-title-28 {
    font-size: 24px;
    line-height: 39px;
  }

  .paragraph-text-28,
  .paragraph-text-26,
  .paragraph-text-24 {
    line-height: 38px;
  }

  .paragraph-text-26 {
    font-size: 23px;
  }

  .paragraph-text-24 {
    font-size: 21px;
  }

  .paragraph-text-21 {
    font-size: 18px;
    line-height: 38px;
  }

  .paragraph-text-19 {
    font-size: 17px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .hidden-991-and-up {
    display: block;
  }

  .hidden-991-and-down {
    display: none;
  }

  /* TITLES & PARAGRAPHS */
  .page-title-82 {
    font-size: 60px;
    line-height: 74px;
  }

  .page-title-80 {
    font-size: 48px;
    line-height: 50px;
  }

  .page-title-75 {
    font-size: 55px;
    line-height: 70px;
  }

  .page-title-70 {
    font-size: 60px;
    line-height: 78px;
  }

  .paragraph-title-34 {
    font-size: 28px;
    line-height: 38px;
  }

  .paragraph-title-28 {
    font-size: 22px;
    line-height: 37px;
  }

  .paragraph-text-28,
  .paragraph-text-26,
  .paragraph-text-24 {
    line-height: 36px;
  }

  .paragraph-text-26 {
    font-size: 22px;
  }

  .paragraph-text-24 {
    font-size: 20px;
  }

  .paragraph-text-21 {
    font-size: 17px;
    line-height: 37px;
  }

  .paragraph-text-19 {
    font-size: 16px;
    line-height: 29px;
  }

  .mtc {
    text-align: center !important;
  }

  .ttext-center {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .hiddenbr {
    display: block;
  }

  .mtext-center {
    text-align: center;
  }

  .ttext-center {
    text-align: left;
  }

  .background-logo {
    background: url("../public/img/background-logo-mobile.png") no-repeat;
    position: absolute;
    background-size: 100vw;
    height: 130vh;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }

  /* TITLES & PARAGRAPHS */
  .page-title-82 {
    font-size: 44px;
    line-height: 64px;
  }

  .page-title-80 {
    font-size: 40x;
    line-height: 50px;
  }

  .page-title-75 {
    font-size: 40px;
    line-height: 50px;
  }

  .page-title-70 {
    font-size: 40px;
    line-height: 50px;
  }

  .paragraph-title-34 {
    font-size: 26px;
    line-height: 36px;
  }

  .paragraph-title-28 {
    font-size: 25px;
    line-height: 43px;
  }

  .paragraph-text-28,
  .paragraph-text-26,
  .paragraph-text-24 {
    line-height: 35px;
  }

  .paragraph-text-26 {
    font-size: 21px;
  }

  .paragraph-text-24 {
    font-size: 21px;
  }

  .paragraph-text-21 {
    font-size: 21px;
    line-height: 35px;
  }

  .paragraph-text-19 {
    font-size: 21px;
    line-height: 35px;
  }

  .mtc {
    text-align: center !important;
  }

  .mmb-20 {
    margin-bottom: 20% !important;
  }

  .mmb-15 {
    margin-bottom: 15% !important;
  }

  .mmb-10 {
    margin-bottom: 10% !important;
  }

  .mmb-5 {
    margin-bottom: 5% !important;
  }
}
</style>
