<template>
  <div class="slider">
    <div class="slide-track-top">
      <div class="slide" v-for="i in 8" :key="i">
        <img :src="`/img/top-carousel-0${i}.png`" alt="" />
      </div>
      <div class="slide" v-for="i in 8" :key="i">
        <img :src="`/img/top-carousel-0${i}.png`" alt="" />
      </div>
      <div class="slide" v-for="i in 8" :key="i">
        <img :src="`/img/top-carousel-0${i}.png`" alt="" />
      </div>
    </div>
  </div>

  <div class="slider">
    <div class="slide-track-bottom">
      <div class="slide" v-for="i in 8" :key="i">
        <img :src="`/img/bot-carousel-0${i}.png`" alt="" />
      </div>
      <div class="slide" v-for="i in 8" :key="i">
        <img :src="`/img/bot-carousel-0${i}.png`" alt="" />
      </div>
      <div class="slide" v-for="i in 8" :key="i">
        <img :src="`/img/bot-carousel-0${i}.png`" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-276px * 8));
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(calc(-276px * 8));
  }
  100% {
    transform: translateX(0);
  }
}

.slider {
  height: 276px;
  margin: auto;
  overflow: hidden;
  width: 100%;
}

.slide-track-top {
  animation: slide-left 80s linear infinite;
  display: flex;
  width: calc(216px * 24);
}

.slide-track-bottom {
  animation: slide-right 80s linear infinite;
  display: flex;
  width: calc(216px * 24);
}

.slide {
  height: 216px;
  width: 216px;
  margin: 60px 30px 0 30px;
}

.slide img {
  height: 216px;
  width: 216px;
}

@media only screen and (max-width: 991px) {
  @keyframes slide-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-223px * 8));
    }
  }

  @keyframes slide-right {
    0% {
      transform: translateX(calc(-223px * 8));
    }
    100% {
      transform: translateX(0);
    }
  }

  .slider {
    height: 238px;
  }

  .slide-track-top {
    animation: slide-left 70s linear infinite;
    display: flex;
    width: calc(163px * 24);
  }

  .slide-track-bottom {
    animation: slide-right 70s linear infinite;
    display: flex;
    width: calc(163px * 24);
  }

  .slide {
    height: 163px;
    width: 163px;
    margin: 20px 30px;
  }

  .slide img{
    width: 163px;
    height: 163px;
  }
}

@media only screen and (max-width: 767px) {
  @keyframes slide-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-168px * 8));
    }
  }

  @keyframes slide-right {
    0% {
      transform: translateX(calc(-168px * 8));
    }
    100% {
      transform: translateX(0);
    }
  }

  .slider {
    height: 198px;
  }

  .slide-track-top {
    animation: slide-left 60s linear infinite;
    display: flex;
    width: calc(138px * 24);
  }

  .slide-track-bottom {
    animation: slide-right 60s linear infinite;
    display: flex;
    width: calc(138px * 24);
  }

  .slide {
    height: 138px;
    width: 138px;
    margin: 0 15px 0 15px;
  }

  .slide img{
    width: 138px;
    height: 100%;
  }
}
</style>