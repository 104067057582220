<template>
  <div class="mb-2"></div>
  <SectionOne />
  <div class="divider mmb-15"></div>
  <SectionTwo />
  <div class="divider mmb-20"></div>
  <SectionThree />
  <div class="divider"></div>
  <SectionFour />
  <div class="divider"></div>
  <SectionFive />
  <div class="divider"></div>
  <SectionSix />
  <div class="mb-8"></div>
  <SectionSeven />
  <div class="divider hidden-xs-only"></div>
  <div class="hidden-sm-and-up mb-15"></div>
  <SectionEight />
  <div class="divider hidden-xs-only"></div>
  <div class="hidden-sm-and-up mb-10"></div>
  <SectionNine />
  <div class="divider"></div>
  <SectionTen />
</template>

<script>
// @ is an alias to /src
import SectionOne from "@/components/SectionOne.vue";
import SectionTwo from "@/components/SectionTwo.vue";
import SectionThree from "@/components/SectionThree.vue";
import SectionFour from "@/components/SectionFour.vue";
import SectionFive from "@/components/SectionFive.vue";
import SectionSix from "@/components/SectionSix.vue";
import SectionSeven from "@/components/SectionSeven.vue";
import SectionEight from "@/components/SectionEight.vue";
import SectionNine from "@/components/SectionNine.vue";
import SectionTen from "@/components/SectionTen.vue";

export default {
  name: "Home",
  components: {
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
    SectionFive,
    SectionSix,
    SectionSeven,
    SectionEight,
    SectionNine,
    SectionTen,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="css" scoped>
.divider {
  margin-bottom: 10%;
}

@media only screen and (max-width: 767px) {
  .divider {
    margin-bottom: 30%;
  }
}
</style>