 <template>
  <el-row id="originals" class="mtext-center mw-1200" style="align-items: center">
    <el-col
      :xs="{ span: 22, offset: 1 }"
      :sm="{ span: 11, offset: 1 }"
      :md="{ span: 12, offset: 0 }"
      :lg="{ span: 12, offset: 0 }"
      :xl="{ span: 12, offset: 0 }"
    >
      <el-row class="page-title-80 mb-10">
        <el-col>NFT</el-col>
        <el-col >Subscriptions</el-col>
      </el-row>
      <el-row class="paragraph-text-24 mb-8 mmb-0">
        <el-col :xs="{ span: 22, offset: 1 }">
         Subscribe to your favorite creator to receive NFT drops directly to your wallet and unpack them to reveal their rarity.</el-col
        >
      </el-row>
      <el-row class="mb-10" style="align-items: center">
        <el-col class="hidden-767-and-down">
          <el-button class="gilroy-bold qualities CommonButton">Common</el-button>
          <el-button class="gilroy-bold qualities RareButton">Rare</el-button>
          <el-button class="gilroy-bold qualities UltraRareButton">Ultra Rare</el-button>
          <!-- <img class="image360" src="/img/section-six-360.svg" /> -->
          <!-- <span style="vertical-align: middle" class="gilroy-bold"> 360</span> -->
        </el-col>
      </el-row>
      <el-row class="mb-10">
        <el-col class="hidden-767-and-down">
          <a href="/downloads/FIRST_Pitchdeck.pdf" target="_blank">
            <el-button class="six-read-more gilroy-bold"> Read More </el-button>
          </a>
        </el-col>
      </el-row>
    </el-col>

    <el-col
      class="hidden-991-and-down"
      :md="12"
      :lg="{ span: 12, offset: 0 }"
      :xl="{ span: 12, offset: 0 }"
    style="text-align:center;">
      <img src="/img/section-six-image1.png" class="section-six-image" />
    </el-col>

    <el-col
    style="text-align:right;"
      class="hidden-991-and-up mmb-5 mmt-m10 six-image1-mobile"
      :xs="{ span: 22, offset: 2 }"
      :sm="{ span: 12, offset: 0 }"
    >
      <img
        src="/img/section-six-image1-mobile.png"
        class="section-six-image section-six-image-mobile"
      />
    </el-col>

    <el-col
     
      class="hidden-767-and-up mb-15 button-section"
      :xs="{ span: 24, offset: 0 }"
      :sm="{ span: 24, offset: 0 }"
    >
      <el-row class="mb-10" style="align-items: center">
        <el-col :sm="{ span: 12, offset: 7 }" class="mmb-8">
          <el-button class="gilroy-bold qualities CommonButton">Common</el-button>
          <el-button class="gilroy-bold qualities RareButton">Rare</el-button>
          <el-button class="gilroy-bold qualities UltraRareButton">Ultra Rare</el-button>
         <!-- <img class="image360" src="/img/section-six-360.svg" /> -->
          <!-- <span style="vertical-align: middle" class="gilroy-bold"> 360</span> -->
        </el-col>
        <el-col :sm="{ span: 4, offset: 0 }">
          <a href="/downloads/FIRST_Pitchdeck.pdf" target="_blank">
            <el-button class="six-read-more gilroy-bold Read-More-button">
              Read More
            </el-button>
          </a>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

 <style lang="css" scoped>

.section-six-image {
  width: 90%;
}

.qualities {
  height: 37px;
  background-color: #191234;
  border-radius: 10px;
  color: #fff;
  margin-right: 1.5%;
  border: none;
}

.qualities:hover,
.qualities:focus,
.qualities:active {
  cursor: default;
  background-color: #191234;
  color: #fff;
  border: none;
}

.six-read-more {
  background-color: transparent;
  color: #191234;
  border: 2px solid #191234;
  border-radius: 26px;
  padding: 0 26px;
}

.six-read-more:hover,
.six-read-more:active,
.six-read-more:focus {
  background-color: transparent;
  color: #191234;
  border: 2px solid #191234;
}

.image360 {
  margin: 0 5px 0 15px;
  vertical-align: middle;
}

.hidden-991-and-up {
  display: none;
}

.hidden-991-and-down {
  display: block;
}

.hidden-767-and-up {
  display: none !important;
}

.hidden-767-and-down {
  display: flex !important;
}

 

@media only screen and (max-width: 991px) {
  .button-section {
    margin-bottom: 5%;
  }
  .section-six-image-mobile {
    margin-top: 10%;
  }
  .Read-More-button {
    margin-top: 10%;
  }
  .hidden-991-and-up {
    display: block;
  }

  .hidden-991-and-down {
    display: none;
  }
  .six-image1-mobile img{
  margin-bottom: 5%;
}
}

@media only screen and (max-width: 767px) {
  .six-image1-mobile img{
  width: 100%;
}
  .mmb-8 {
    margin-bottom: 8%;
  }

  .mtext-center {
    text-align: center;
  }

  .mmb-0 {
    margin-bottom: 0%;
  }

  .hidden-767-and-up {
    display: flex !important;
  }

  .hidden-767-and-down {
    display: none !important;
  }

  .mmt-m10{
    margin-top: -10%;
  }
}


.CommonButton{
  background-color: #5148F2;
  min-width: 105px;
  font-size: 16px;
}
.RareButton{
    background-image: linear-gradient(to right, #822DDC  , #D479FF);
  min-width: 105px;
  font-size: 16px;
}
.UltraRareButton{
    background-image: linear-gradient(to right, #F7B500, #B620E0, #3266FF );
  min-width: 105px;
  font-size: 16px;
}

</style>