import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  //{
  //  path: '/team',
  //  name: 'Team',
  //  component: () => import(/* webpackChunkName: "team" */ '../views/Team.vue')
  //},

  {
    // Redirect all to home
    path: '/:catchAll(.*)',
    redirect: '/'
  }

]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    console.log(from, savedPosition);
    if (to.hash) {
      // BEFORE:
      // return { selector: to.hash }

      return { el: to.hash }
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
