<template>
  <div id="stream" class="section-five-gradient">
    <div class="mw-1200">
      <el-row justify="space-around" class="mb-2">
        <el-col
          :xs="{ span: 18 }"
          class="page-title-70 text-center"
          style="margin: 5% 0"
        >
          Abracadabra, You're <span class="pink-text">&bull; live</span>
        </el-col>
      </el-row>

      <el-row class="align-center mb-10 mtext-center">
        <el-col
          :xs="{ span: 22, offset: 1 }"
          :sm="{ span: 10, offset: 1 }"
          :md="{ span: 10, offset: 2 }"
          :lg="{ span: 10, offset: 2 }"
          :xl="{ span: 8, offset: 2 }"
        >
          <img src="/img/section-five-image1.png" class="section-five-image" />
        </el-col>
        <el-col
          :xs="{ span: 20, offset: 2 }"
          :sm="{ span: 10, offset: 1 }"
          :md="{ span: 9, offset: 2 }"
          :lg="{ span: 9, offset: 2 }"
          :xl="{ span: 9, offset: 3 }"
        >
          <el-row>
            <el-col
              class="
                paragraph-title-28
                gilroy-extrabold
                mb-3
                FirstAccessPassText
              "
            >
              NFT Access Pass
            </el-col>
            <el-col class="paragraph-text-19" :span="24">
              Streamers, gamers, artists and other creators can use the NFT Access Pass to
              <span class="gilroy-bold">monetize live streams, do special giveaways</span>
                , sell NFTs and invite their fans in unlocking rare NFT packs. Watch content and experience streaming together like never before.
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row justify="space-around" class="mb-8">
        <el-col
          class="hidden-767-and-down"
          :xs="{ span: 24 }"
          :sm="{ span: 20 }"
          :md="{ span: 20 }"
          :lg="{ span: 20 }"
          :xl="{ span: 19 }"
        >
          <img
            src="/img/section-five-image2.png"
            class="section-five-image image-shadow"
          />
        </el-col>
      </el-row>

      <el-row class="mb-8">
        <el-col
          class="hidden-767-and-up"
          :xs="{ span: 23 }"
          :sm="{ span: 22 }"
          :md="{ span: 20 }"
          :lg="{ span: 20 }"
          :xl="{ span: 19 }"
        >
          <img
            src="/img/section-five-image2-mobile.png"
            class="section-five-image image-shadow section-five-image-mobile"
          />
        </el-col>
      </el-row>

      <el-row id="features" class="text-center">
        <el-col
          :xs="{ span: 20, offset: 2 }"
          :sm="{ span: 14, offset: 5 }"
          :md="{ span: 6, offset: 1 }"
        >
          <el-row class="mmb-15 mmt-10">
            <el-col class="mb-15 mmb-0">
              <img class="t-svg" src="/img/nft_access_pass.svg" />
            </el-col>
            <el-col class="paragraph-title-28 gilroy-extrabold mb-10 mmb-5">
              NFT Access Pass
            </el-col>
            <el-col>
              <span class="paragraph-text-19">
                Access to live streams, unique unlockables, special edition content and more!
              </span>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :xs="{ span: 16, offset: 4 }"
          :sm="{ span: 10, offset: 7 }"
          :md="{ span: 6, offset: 2 }"
        >
          <el-row class="mmb-15 mmt-10">
            <el-col class="mb-15 mmb-0">
              <img class="t-svg" src="/img/cross_chain.svg" />
            </el-col>
            <el-col class="paragraph-title-28 gilroy-extrabold mb-10 mmb-5">
              Cross-chain
            </el-col>
            <el-col
              :xs="{ span: 22, offset: 1 }"
              :lg="{ span: 18, offset: 3 }"
              class="paragraph-text-19 mmb-10"
            >
              Buy, mint, sell, farm, stake and own NFTs on multiple blockchains.
            </el-col>
          </el-row>
        </el-col>
        <el-col
          class="mmb-5"
          :xs="{ span: 22, offset: 1 }"
          :sm="{ span: 12, offset: 6 }"
          :md="{ span: 6, offset: 2 }"
        >
          <el-row class="mmb-15 mmt-10">
            <el-col class="mb-15 mmb-0">
              <img class="t-svg" src="/img/subscriptions.svg" />
            </el-col>
            <el-col class="paragraph-title-28 gilroy-extrabold mb-10 mmb-5">
              Content subscriptions
            </el-col>
            <el-col class="paragraph-text-19">
              Decentralized funds give access to unique content, channel &
              platform subscriptions.
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style scoped>
.section-five-gradient {
  background: transparent
    linear-gradient(
      180deg,
      rgba(124, 100, 167, 0.1) 0%,
      rgba(243, 241, 242, 0.1) 59%,
      rgba(150, 129, 189, 0.1) 100%
    )
    0% 0% no-repeat padding-box;
  padding-bottom: 10%;
}

.section-five-image {
  width: 100%;
}

.image-shadow {
  border-radius: 34px;
  box-shadow: -20px 20px 70px -20px rgba(185, 177, 209, 1);
  -webkit-box-shadow: -20px 20px 70px -20px rgba(185, 177, 209, 1);
  -moz-box-shadow: -20px 20px 70px -20px rgba(185, 177, 209, 1);
}

#features .el-col .el-row {
  text-align: center;
  /* margin-top: 10%; */
}

.hidden-991-and-up {
  display: none;
}

.hidden-991-and-down {
  display: block;
}

.hidden-767-and-up {
  display: none !important;
}

.hidden-767-and-down {
  display: flex !important;
}

@media only screen and (max-width: 991px) {
  .hidden-991-and-up {
    display: block;
  }

  .hidden-991-and-down {
    display: none;
  }

  .section-five-image {
    border-radius: 0 25px 25px 0;
  }

  .mmb-15 {
    margin-bottom: 15% !important;
  }

  .mmb-10 {
    margin-bottom: 5% !important;
  }

  .mmb-5 {
    margin-bottom: 2% !important;
  }

  .mmb-0 {
    margin-bottom: 0% !important;
  }

  #features {
    margin-top: 10%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .section-five-gradient {
    padding-top: 30px !important;
    margin-top: 0px !important;
  }

  .t-svg {
    width: 100px;
    margin-bottom: 5%;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 767px) {
  #features {
    margin-top: 15%;
  }
  .FirstAccessPassText {
    margin-top: 15%;
  }
  .section-five-image-mobile {
    margin-top: 12%;
  }
  .mmb-15 {
    margin-bottom: 15% !important;
  }

  .mmb-10 {
    margin-bottom: 10% !important;
  }

  .mmb-5 {
    margin-bottom: 5% !important;
  }

  .mmb-0 {
    margin-bottom: 0% !important;
  }

  .mmt-10 {
    margin-top: 10%;
  }

  .hidden-767-and-up {
    display: flex !important;
  }

  .hidden-767-and-down {
    display: none !important;
  }
}
</style>