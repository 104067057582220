<template>
  <div class="shown-mobile">
    <el-row class="mb-15">
      <el-col :span="9">
        <img src="/img/three-210-01-mobile.png" alt="" class="mad-image" />
      </el-col>
      <el-col :span="12" :offset="3">
        <el-row>
          <el-col :span="9" :offset="12">
            <img src="/img/three-83-02.png" alt="" class="mad-image ost-m20" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="8">
            <img
              src="/img/three-163-01-mobile.png"
              alt=""
              class="mad-image ost-20"
            />
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row>
      <el-col class="gilroy-bold three-title-m text-center mb-10">
        Big News<span class="pink-text">.</span>
      </el-col>
    </el-row>

    <el-row
      justify="space-around"
      class="paragraph-text-24 text-center mb-15 three-text-blocks"
    >
      <el-col :sm="{ span: 11, offset: 0 }" class="mb-5">
        Buy, mint, sell, farm, stake and unlock unique NFTs from your favorite creators.
      </el-col>
      <el-col></el-col>
      <el-col :sm="{ span: 11, offset: 0 }" class="mb-5" style="padding: 0 1%">
        <b>Subscribe</b> to your favorite creator to receive NFT drops directly to your wallet during live streams or NFT subscriptions.
      </el-col>
      <el-col></el-col>
      <el-col :sm="{ span: 10, offset: 0 }" class="mb-5">
        <b>
          Creators and viewers can earn together while sharing unique
          moments.</b
        >
      </el-col>
    </el-row>

    <el-row class="mb-15">
      <el-col :span="10"
        ><img src="/img/three-199-01-mobile.png" class="mad-image" alt=""
      /></el-col>
      <el-col :span="9" :offset="5"
        ><img
          src="/img/three-199-02-mobile.png"
          class="mad-image ost-40"
          alt=""
      /></el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <el-row>
          <el-col :span="10" :offset="6"
            ><img src="/img/three-89-02.png" alt="" class="mad-image"
          /></el-col>
          <el-col class="mb-15">&emsp;</el-col>
          <el-col :span="11"
            ><img src="/img/three-134-04-mobile.png" alt="" class="mad-image"
          /></el-col>
        </el-row>
      </el-col>
      <el-col :span="8" :offset="0">
        <img src="/img/three-163-03.png" alt="" class="mad-image ost-15" />
      </el-col>
    </el-row>
  </div>

  <div class="shown-tablet mb-20">
    <el-row class="mb-15">
      <el-col :span="6">
        <img src="/img/three-210-01-mobile.png" alt="" class="mad-image" />
      </el-col>
      <el-col :span="8" :offset="1">
        <el-row>
          <el-col :span="16" :offset="3">
            <img src="/img/three-119-04.png" alt="" class="mad-image ost-m20" />
          </el-col>
          <el-col :span="9" :offset="0">
            <img src="/img/three-83-03.png" alt="" class="mad-image ost-70" />
          </el-col>

          <el-col :span="12" :offset="3">
            <img
              src="/img/three-134-02.png"
              alt=""
              class="mad-image ost-m10"
              style="margin-left: 40%"
            />
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="8" :offset="1">
        <el-row>
          <el-col :span="9" :offset="5">
            <img src="/img/three-83-02.png" alt="" class="mad-image ost-m20" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" :offset="8">
            <img
              src="/img/three-163-01-mobile.png"
              alt=""
              class="mad-image ost-60"
            />
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row>
      <el-col class="gilroy-bold three-title-m text-center mb-10">
        Big News<span class="pink-text">.</span>
      </el-col>
    </el-row>

    <el-row
      justify="space-around"
      class="paragraph-text-24 text-center mb-15 three-text-blocks"
    >
      <el-col
        :xs="{ span: 18, offset: 0 }"
        :sm="{ span: 13, offset: 0 }"
        class="mb-5"
      >
        Buy, mint, sell, farm, stake and unlock unique NFTs from your favorite creators.
      </el-col>
      <el-col></el-col>
      <el-col
        :xs="{ span: 20, offset: 0 }"
        :sm="{ span: 16, offset: 0 }"
        class="mb-5"
        style="padding: 0 1%"
      >
        <span class="gilroy-bold">Subscribe </span> to your favorite creator to receive NFT drops directly to your wallet during live streams or NFT subscriptions.
      </el-col>
      <el-col></el-col>
      <el-col
        :xs="{ span: 18, offset: 0 }"
        :sm="{ span: 15, offset: 0 }"
        class="mb-5 mmb-15 gilroy-bold"
      >
        Creators and viewers can earn together while sharing unique moments.
      </el-col>
    </el-row>

    <el-row class="mb-15">
      <el-col :span="6"
        ><img src="/img/three-199-01-mobile.png" class="mad-image" alt=""
      /></el-col>
      <el-col :span="3" :offset="1"
        ><img src="/img/three-143-01.png" class="mad-image ost-70" alt=""
      /></el-col>
      <el-col :span="5" :offset="1"
        ><img src="/img/three-199-03.png" class="mad-image ost-m10" alt=""
      /></el-col>
      <el-col :span="6" :offset="2"
        ><img
          src="/img/three-199-02-mobile.png"
          class="mad-image ost-40"
          alt=""
      /></el-col>
    </el-row>

    <el-row>
      <el-col :span="5" :offset="1"
        ><img src="/img/three-134-04.png" alt="" class="mad-image"
      /></el-col>
      <el-col :span="4" :offset="7"
        ><img src="/img/three-89-02.png" alt="" class="mad-image ost-m20"
      /></el-col>
      <el-col :span="3" :offset="4">
        <img src="/img/three-163-03.png" alt="" class="mad-image ost-35" />
      </el-col>
    </el-row>
  </div>

  <div id="section-three-wrapper" class="hidden-1201-and-down">
    <el-row justify="" align="top" class="image-row text-center">
      <el-col :span="2" :offset="2">
        <img
          class="ost-5 parallax-image"
          :data-speed="speed83"
          src="/img/three-83-01.png"
          alt=""
        />
      </el-col>
      <el-col :span="6" :offset="1">
        <img
          class="ost-20 parallax-image"
          :data-speed="speed210"
          src="/img/three-210-01.png"
          alt=""
        />
      </el-col>
      <el-col :span="2" :offset="1">
        <img
          class="ost-40 parallax-image"
          :data-speed="speed83"
          src="/img/three-83-02.png"
          alt=""
        />
      </el-col>
      <el-col :span="4" :offset="2">
        <img
          class="ost-45 parallax-image"
          :data-speed="speed163"
          src="/img/three-163-01.png"
          alt=""
        />
      </el-col>
      <el-col :span="4" :offset="0">
        <img
          class="ost-10 parallax-image"
          :data-speed="speed134"
          src="/img/three-134-01.png"
          alt=""
        />
      </el-col>
    </el-row>
    <el-row justify="" align="top" class="image-row">
      <el-col :span="4" :offset="1">
        <img
          class="ost-m5 parallax-image"
          :data-speed="speed119"
          src="/img/three-119-04.png"
          alt=""
        />
      </el-col>
      <el-col :span="4" :offset="5">
        <img
          class="ost-40 parallax-image"
          :data-speed="speed134"
          src="/img/three-134-02.png"
          alt=""
        />
      </el-col>
      <el-col :span="6" :offset="0">
        <img
          class="ost-65 parallax-image"
          :data-speed="speed89"
          src="/img/three-199-01.png"
          alt=""
        />
      </el-col>
      <el-col :span="4" :offset="0">
        <img
          class="ost-50 parallax-image"
          :data-speed="speed143"
          src="/img/three-143-01.png"
          alt=""
        />
      </el-col>
    </el-row>

    <el-row justify="" align="top" class="image-row">
      <el-col :span="2" :offset="4">
        <img
          class="ost-m10 parallax-image"
          :data-speed="speed89"
          src="/img/three-89-01.png"
          alt=""
        />
      </el-col>
    </el-row>

    <el-row justify="" align="top" class="image-row">
      <el-col :span="6" :offset="0">
        <img
          class="ost-60 parallax-image"
          :data-speed="speed199"
          src="/img/three-199-02.png"
          alt=""
        />
      </el-col>
      <el-col :span="4" :offset="0">
        <img
          class="ost-25 parallax-image"
          :data-speed="speed134"
          src="/img/three-134-03.png"
          alt=""
        />
      </el-col>
      <el-col
        id="text-row"
        :span="4"
        :offset="0"
        class="gilroy-bold three-title text-center"
      >
        Big News<span class="pink-text">.</span>
      </el-col>
      <el-col :span="4" :offset="5" class="text-end" style="padding-right: 2%">
        <img
          class="ost-65 parallax-image"
          :data-speed="speed143"
          src="/img/three-143-02.png"
          alt=""
        />
      </el-col>
    </el-row>

    <el-row style="margin-top: 13%">
      <el-col :span="4" :offset="4">
        <img
          class="parallax-image"
          :data-speed="speed163"
          src="/img/three-163-02.png"
          alt=""
        />
      </el-col>
      <el-col
        :class="{ 'fade-in': bigNewsFinish }"
        :span="8"
        :offset="0"
        class="paragraph-text-24 mb-3 text-center three-text"
        style="padding: 0 3%"
      >
        Buy, mint, sell, farm, stake and unlock unique NFTs from your favorite creators.
      </el-col>
      <el-col :span="2" :offset="0">
        <img
          class="parallax-image"
          style="position: relative; top: -50%"
          :data-speed="speed89"
          src="/img/three-89-02.png"
          alt=""
        />
      </el-col>
    </el-row>

    <el-row>
      <el-col
        :class="{ 'fade-in': bigNewsFinish }"
        :span="6"
        :offset="9"
        class="paragraph-text-24 mb-3 text-center three-text"
      >
                <span class="gilroy-bold">Subscribe </span> to your favorite creator to receive NFT drops directly to your wallet during live streams or NFT subscriptions.

      </el-col>
      <el-col :span="4" :offset="3">
        <img
          class="parallax-image"
          :data-speed="speed199"
          src="/img/three-199-03.png"
          alt=""
        />
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="4" :offset="1">
        <img
          class="parallax-image"
          :data-speed="speed199"
          src="/img/three-199-04.png"
          alt=""
        />
      </el-col>
      <el-col :span="3" :offset="1">
        <img
          class="parallax-image ost-65"
          :data-speed="speed163"
          src="/img/three-163-04.png"
          alt=""
        />
      </el-col>
      <el-col
        :class="{ 'fade-in': bigNewsFinish }"
        :span="6"
        :offset="0"
        class="paragraph-text-24 gilroy-bold text-center three-text"
      >
        Creators and viewers can earn together while sharing unique moments.
      </el-col>
      <el-col :span="3" :offset="1">
        <img
          class="parallax-image ost-40"
          :data-speed="speed119"
          src="/img/three-119-03.png"
          alt=""
        />
      </el-col>
      <el-col :span="3" :offset="2" class="text-end">
        <img
          class="parallax-image ost-25"
          :data-speed="speed163"
          src="/img/three-163-03crop.png"
          alt=""
        />
      </el-col>
    </el-row>

    <el-row>
      <el-col class="mb-5">&nbsp;</el-col>
    </el-row>

    <el-row>
      <el-col :span="2" :offset="0" class="text-center">
        <img
          class="parallax-image ost-m10"
          :data-speed="speed83"
          src="/img/three-83-04.png"
          alt=""
        />
      </el-col>
      <el-col :span="3" :offset="1">
        <img
          class="parallax-image ost-m10"
          :data-speed="speed119"
          src="/img/three-119-02.png"
          alt=""
        />
      </el-col>
      <el-col :span="2" :offset="5">
        <img
          class="parallax-image"
          :data-speed="speed83"
          src="/img/three-83-03.png"
          alt=""
        />
      </el-col>
      <el-col :span="3" :offset="7">
        <img
          class="parallax-image ost-m10"
          :data-speed="speed119"
          src="/img/three-119-01.png"
          alt=""
        />
      </el-col>
    </el-row>

    <el-row>
      <el-col class="mb-3">&nbsp;</el-col>
    </el-row>

    <el-row>
      <el-col :span="4" :offset="7">
        <img
          class="parallax-image"
          :data-speed="speed163"
          src="/img/three-163-05.png"
          alt=""
        />
      </el-col>
      <el-col :span="4" :offset="3">
        <img
          class="parallax-image ost-30"
          :data-speed="speed119"
          src="/img/three-163-06.png"
          alt=""
        />
      </el-col>
      <el-col :span="3" :offset="3" class="text-end">
        <img
          class="parallax-image ost-m10"
          :data-speed="speed119"
          src="/img/three-134-04.png"
          alt=""
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  data: () => ({
    bigNewsFinish: false,
    speed83: 0.05,
    speed89: 0.1,
    speed119: 0.15,
    speed134: 0.2,
    speed143: 0.25,
    speed163: 0.3,
    speed199: 0.35,
    speed210: 0.35,
  }),
  computed: {
    wrapperHeight: function () {
      // console.log(
      //   "height",
      //   document.getElementById("section-three-wrapper").clientHeight
      // );

      // console.log("all", document.getElementById("section-three-wrapper"));
      return document.getElementById("section-three-wrapper").offsetHeight;
    },
  },
  mounted: function () {
    var self = this;
    document.addEventListener("DOMContentLoaded", function () {
      gsap.to(".three-title", {
        scrollTrigger: {
          trigger: "#text-row",
          pin: "#text-row",
          start: "top 66%",
          onLeave: function () {
            console.log("leave");
            self.bigNewsFinish = true;
          },
          onEnterBack: function () {
            console.log("enterBack");
            self.bigNewsFinish = false;
          },
          end: "+=600",
          scrub: true,
          // markers: true,
        },
        y: () => -800 * 0.3,
        ease: "none",
      });

      gsap.to(".parallax-image", {
        y: (i, target) => -self.wrapperHeight * target.dataset.speed,
        ease: "none",
        scrollTrigger: {
          trigger: ".parallax-image",
          start: "top center",
          end: "+=" + self.wrapperHeight * 2,
          scrub: true,
          // markers: true,
        },
      });
    });
  },
  updated: function () {},
  methods: {},
};
</script>

<style lang="css" scoped>
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .three-text-blocks .mb-10 {
    margin-bottom: 5%;
  }
  .three-text-blocks {
    margin-top: -5%;
  }
}

/* mad = md-and-down */
.mad-image {
  width: 100%;
}

.parallax-image {
  width: 50%;
}

.three-title {
  position: relative;
  font-size: 50px;
  line-height: 60px;
}

img[class*="ost"] {
  position: relative;
}

.el-col[class*="three-text"] {
  visibility: hidden;
}

.fade-in {
  visibility: visible !important;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ost-5 {
  top: 5%;
}

.ost-10 {
  top: 10%;
}

.ost-15 {
  top: 15%;
}

.ost-20 {
  top: 20%;
}

.ost-25 {
  top: 25%;
}

.ost-30 {
  top: 30%;
}

.ost-35 {
  top: 35%;
}

.ost-40 {
  top: 40%;
}

.ost-45 {
  top: 45%;
}

.ost-50 {
  top: 50%;
}

.ost-55 {
  top: 55%;
}

.ost-60 {
  top: 60%;
}

.ost-65 {
  top: 65%;
}

.ost-70 {
  top: 70%;
}

.ost-75 {
  top: 75%;
}

.ost-m5 {
  top: -5%;
}

.ost-m10 {
  top: -10%;
}

.ost-m20 {
  top: -25%;
}

.ost-m100 {
  top: -100%;
}

.hidden-1201-and-up {
  display: none;
}

.hidden-1201-and-down {
  display: block;
}

.hidden-991-and-up {
  display: none;
}

.hidden-991-and-down {
  display: block;
}

.shown-tablet {
  display: none;
}

.shown-mobile {
  display: none;
}

@media only screen and (max-width: 1700px) {
  .three-title {
    margin-bottom: 12%;
  }
}

@media only screen and (max-width: 1600px) {
  .three-title {
    margin-bottom: 13%;
  }
}

@media only screen and (max-width: 1500px) {
  .three-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 15%;
  }
}

@media only screen and (max-width: 1300px) {
  .three-title {
    margin-bottom: 19%;
  }
}

@media only screen and (max-width: 1200px) {
  .hidden-1201-and-up {
    display: block;
  }

  .hidden-1201-and-down {
    display: none;
  }

  .three-title-m {
    font-size: 45px;
    line-height: 55px;
  }

  .shown-tablet {
    display: block;
  }
}

@media only screen and (max-width: 1199px) {
  .three-title-m {
    font-size: 40px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .three-title-m {
    font-size: 40px;
    line-height: 60px;
  }

  .hidden-991-and-up {
    display: block;
  }

  .hidden-991-and-down {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .three-title-m {
    font-size: 40px;
    line-height: 60px;
  }
  .paragraph-text-24 {
    width: 88%;
    margin-right: 6%;
    margin-left: 6%;
    margin-bottom: 3%;
  }

  .shown-tablet {
    display: none;
  }

  .shown-mobile {
    display: block;
  }
}

@media only screen and (max-width: 400px) {
  .three-title-m {
    font-size: 40px;
    line-height: 60px;
  }
}
</style>