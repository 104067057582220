<template>
  <div id="nav-menu-wrapper" class="nav-menu-wrapper">
    <el-menu class="nav-menu" :router="true" mode="horizontal">
      <el-menu-item class="logo" index="Home" :route="{ name: 'Home' }">
        <img src="/img/nav-logo.svg" />
      </el-menu-item>
      <el-menu-item class="nav-menu-item" index="Home" :route="{ name: 'Home', hash: '#nfts' }">
        NFTs
      </el-menu-item>
      <el-menu-item class="nav-menu-item" index="Home" :route="{ name: 'Home', hash: '#stream' }">
        Stream
      </el-menu-item>
      <el-menu-item class="nav-menu-item" index="Home" :route="{ name: 'Home', hash: '#originals' }">
        Subscriptions
      </el-menu-item>
      <el-menu-item class="nav-menu-item" index="Home" :route="{ name: 'Home', hash: '#about' }">
        About
      </el-menu-item>
      <el-menu-item class="nav-menu-item">
        <a href="/downloads/FIRST__Tokenomics.pdf" style="vertical-align: baseline" target="_blank">Tokenomics</a>
      </el-menu-item>
      <el-menu-item class="nav-menu-item">
        <a href="https://firstone.gitbook.io/first/development/master" style="vertical-align: baseline"
          target="_blank">Roadmap</a></el-menu-item>
      <!-- <el-menu-item
        class="nav-menu-item"
        index="Team"
        :route="{ name: 'Team' }"
      >
        Team
      </el-menu-item> -->
      <el-menu-item class="nav-menu-item socials">
        <a href="https://firstnft.medium.com" target="_blank"><img src="/img/socials-medium.svg" /></a>

      </el-menu-item>
    </el-menu>
  </div>

  <div id="burger-menu-wrapper" class="burger-menu-wrapper" :class="{ 'burger-menu-background': showBurgerNav }">
    <el-menu class="burger-nav-menu" :router="true" mode="vertical">
      <el-menu-item index="Home" :route="{ name: 'Home' }" @click="
        showBurgerNav = false;
      hamOff();
      ">
        <div class="burger-menu-logo-wrapper">
          <img src="/img/nav-logo.svg" />
        </div>
      </el-menu-item>
      <div class="hamburger">
        <svg id="da-ham" class="ham hamRotate ham7" viewBox="0 0 100 100" width="60"
          @click="showBurgerNav = !showBurgerNav" onclick="this.classList.toggle('active')">
          <path class="line top" d="m 70,33 h -40 c 0,0 -6,1.368796 -6,8.5 0,7.131204 6,8.5013 6,8.5013 l 20,-0.0013" />
          <path class="line middle" d="m 70,50 h -40" />
          <path class="line bottom"
            d="m 69.575405,67.073826 h -40 c -5.592752,0 -6.873604,-9.348582 1.371031,-9.348582 8.244634,0 19.053564,21.797129 19.053564,12.274756 l 0,-40" />
        </svg>
      </div>
    </el-menu>
  </div>

  <transition style="background-color: #f3f1f2" name="el-fade-in-linear">
    <el-menu class="burger-nav-menu vh100 mtc" :router="true" v-show="showBurgerNav" mode="vertical"
      style="position: fixed; top: 60px !important; left: 0">
      <el-menu-item class="nav-menu-item">
        <a href="/#nfts" @click="
          showBurgerNav = false;
        hamOff();
        ">NFTs</a>
      </el-menu-item>
      <el-menu-item class="nav-menu-item">
        <a href="/#stream" @click="
          showBurgerNav = false;
        hamOff();
        ">Stream</a>
      </el-menu-item>
      <el-menu-item class="nav-menu-item">
        <a href="/#originals" @click="
          showBurgerNav = false;
        hamOff();
        ">Subscriptions</a>
      </el-menu-item>
      <el-menu-item class="nav-menu-item">
        <a href="/#about" @click="
          showBurgerNav = false;
        hamOff();
        ">About</a>
      </el-menu-item>
      <el-menu-item class="nav-menu-item">
        <a href="/downloads/FIRST__Tokenomics.pdf" style="vertical-align: baseline" target="_blank" @click="
          showBurgerNav = false;
        hamOff();
        ">Tokenomics</a>
      </el-menu-item>
      <el-menu-item class="nav-menu-item">
        <a href="https://firstone.gitbook.io/first/development/master" target="_blank" @click="
          showBurgerNav = false;
        hamOff();
        ">Roadmap</a>
      </el-menu-item>
      <el-menu-item class="nav-menu-item" @click="
        showBurgerNav = false;
      hamOff();
      ">
        <router-link to="/team">Team</router-link>
      </el-menu-item>
      <el-menu-item class="nav-menu-item">
        <a href="https://firstnft.medium.com" target="_blank" @click="
          showBurgerNav = false;
        hamOff();
        ">
          <img style="margin-left: 0 !important" src="/img/socials-medium.svg" />
        </a>
        <a href="https://discord.gg/firstnft" target="_blank" @click="
          showBurgerNav = false;
        hamOff();
        ">
          <img src="/img/socials-discord.svg" />
        </a>
        <a href="https://twitter.com/FirstOneNFT" target="_blank" @click="
          showBurgerNav = false;
        hamOff();
        ">
          <img src="/img/socials-twitter.svg" />
        </a>
        <a href="https://t.me/Firstonenft" target="_blank" @click="
          showBurgerNav = false;
        hamOff();
        ">
          <img src="/img/socials-telegram.svg" />
        </a>
      </el-menu-item>
    </el-menu>
  </transition>
</template>

<script>
export default {
  setup() { },
  data: () => ({
    showBurgerNav: false,
  }),
  mounted() {
    var self = this;
    visualViewport.onresize = function () {
      self.showBurgerNav = false;
      self.hamOff();
    };
  },
  methods: {
    hamToggle() {
      document.getElementById("da-ham").classList.toggle("active");
    },
    hamOff() {
      document.getElementById("da-ham").classList.remove("active");
    },
  },
};
</script>

<style lang="css" scoped>
* {
  font-family: "GilroyBold" !important;
}

a,
.el-menu-item,
.el-menu-item.is-active {
  color: #191234 !important;
  text-decoration: none;
}

.hamburger {
  display: inline-block;
  position: absolute;
  z-index: 999;
  right: 0;
  top: -5%;
}

.burger-socials {
  display: block;
  width: 120px;
  float: right;
}

.nav-menu-wrapper,
.burger-menu-wrapper {
  position: fixed;
  background-color: #f3f1f2;
  width: 100%;
  z-index: 9;
  top: 0;
}

.nav-menu-wrapper {
  height: 85px;
}

.burger-menu-wrapper {
  display: none;
  height: 60px;
}

.burger-menu-background {
  background-color: #f3f1f2 !important;
}

.nav-menu {
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 17%;
  right: 17.9%;
  height: 85px;
  align-items: center;
}

.burger-nav-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
}

.vh100 {
  height: 100vh;
}

.burger-nav-menu .el-menu-item a {
  font-size: 17px;
  line-height: 24px;
}

.nav-menu-item {
  flex-grow: 1;
}

.el-menu .el-menu-item a img {
  margin: 0 1%;
}

.el-menu--horizontal .nav-menu-item a img {
  margin: 0 50% 0 -30%;
  height: 12px;
  vertical-align: baseline;
}

.el-menu .nav-menu-item a img {
  margin: 0 5%;
  height: 14px;
  vertical-align: baseline;
}

.el-menu {
  background-color: transparent;
}

.el-menu.el-menu--horizontal,
.el-menu.el-menu--vertical {
  border: none;
}

.el-menu--horizontal>.el-menu-item.is-active,
.el-menu--vertical>.el-menu-item.is-active {
  border: none;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--vertical .el-menu-item:not(.is-disabled):focus,
.el-menu--vertical .el-menu-item:not(.is-disabled):hover {
  background-color: transparent;
}

.el-menu-item:hover {
  background-color: transparent;
}

.logo {
  flex-grow: 10;
}

.logo img {
  margin-top: -5%;
  width: 100px;
}

.burger-menu-logo-wrapper {
  width: 89px;
}

#nav-bar {
  margin: 5% 0;
  display: flex;
  list-style-type: none;
  padding: 0;
  align-items: center;
}

#nav-bar li {
  margin: 0 2%;
}

#nav-bar li:first-of-type {
  margin-left: 0;
}

#nav-bar li:last-of-type {
  margin-right: 0;
}

#nav-bar li a {
  text-decoration: none;
  color: #191234;
}

#nav-bar li a:visited {
  color: #191234;
}

#nav-bar #nav-logo {
  margin-right: 280px;
}

.socials {
  flex-grow: 5;
}

.socials a {
  margin: 0 3%;
}

@media only screen and (max-width: 2600px) {
  .nav-menu {
    left: 25%;
    right: 25.5%;
  }
}

@media only screen and (max-width: 2500px) {
  .nav-menu {
    left: 24%;
    right: 24.5%;
  }
}

@media only screen and (max-width: 2400px) {
  .nav-menu {
    left: 23%;
    right: 24%;
  }
}

@media only screen and (max-width: 2300px) {
  .nav-menu {
    left: 22%;
    right: 23%;
  }
}

@media only screen and (max-width: 2200px) {
  .nav-menu {
    left: 21%;
    right: 21.5%;
  }
}

@media only screen and (max-width: 2100px) {
  .nav-menu {
    left: 20%;
    right: 20.5%;
  }
}

@media only screen and (max-width: 2000px) {
  .nav-menu {
    left: 17%;
    right: 18%;
  }
}

@media only screen and (max-width: 1900px) {
  .nav-menu {
    left: 18%;
    right: 17%;
  }
}

@media only screen and (max-width: 1800px) {
  .nav-menu {
    left: 17.5%;
    right: 16%;
  }
}

@media only screen and (max-width: 1700px) {
  .nav-menu {
    left: 16%;
    right: 14%;
  }
}

@media only screen and (max-width: 1600px) {
  .nav-menu {
    left: 13%;
    right: 11%;
  }
}

@media only screen and (max-width: 1500px) {
  .nav-menu {
    left: 10%;
    right: 8.5%;
  }
}

@media only screen and (max-width: 1400px) {
  .nav-menu {
    left: 6%;
    right: 4.5%;
  }
}

@media only screen and (max-width: 1300px) {
  .nav-menu {
    left: 5%;
    right: 5%;
  }
}

@media only screen and (max-width: 1199px) {
  #nav-bar #nav-logo {
    margin-right: 40px;
  }

  .nav-menu {
    left: 7%;
    right: 7%;
  }
}

@media only screen and (max-width: 1120px) {
  .desktop-navbar {
    display: none;
  }

  .nav-menu {
    left: 5%;
    right: 5%;
  }

  #burger-navbar-items .el-col a {
    width: 33%;
    text-decoration: none;
    padding: 0;
    margin: 0;
    height: 30px;
    border: 2px solid #ccc;
  }

  .ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .hamRotate.active {
    transform: rotate(45deg);
  }

  .hamRotate180.active {
    transform: rotate(180deg);
  }

  .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #000;
    stroke-width: 5.5;
    stroke-linecap: round;
  }

  .ham7 .top {
    stroke-dasharray: 40 82;
  }

  .ham7 .middle {
    stroke-dasharray: 40 111;
  }

  .ham7 .bottom {
    stroke-dasharray: 40 161;
  }

  .ham7.active .top {
    stroke-dasharray: 17 82;
    stroke-dashoffset: -62px;
  }

  .ham7.active .middle {
    stroke-dashoffset: 23px;
  }

  .ham7.active .bottom {
    stroke-dashoffset: -83px;
  }
}

@media only screen and (max-width: 1000px) {
  .nav-menu {
    left: 2%;
    right: 2%;
  }
}

@media only screen and (max-width: 950px) {
  .nav-menu-wrapper {
    display: none;
  }

  .burger-menu-wrapper {
    display: block;
  }

  .topnav {
    display: block;
  }
}
</style>