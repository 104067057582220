<template>
  <el-row id="nfts" class="mw-1200 section-four-top-side mtext-center mmb-15">
    <el-col
      :xs="{ span: 20, offset: 2 }"
      :sm="{ span: 10, offset: 1 }"
      :xl="{ span: 10, offset: 0 }"
      class="page-title-75"
    >
      <el-row class="mb-5 tmt-m6h">
        <el-col class="pink-text">NFTs</el-col>
        <el-col>Better than ever before.</el-col>
      </el-row>
    </el-col>
    <el-col
      :xs="{ span: 20, offset: 2 }"
      :sm="{ span: 13, offset: 0 }"
      :xl="{ span: 11, offset: 3 }"
      class="paragraph-text-24 mb-3"
      style="padding-right: 2%"
    >
      Capitalize on your streaming and 
      <span class="gilroy-bold">monetize your video content in all new ways.</span> Whether you're a streamer, gamer or artist, with FIRST your fans can join you in an all new streaming experience.
    </el-col>
  </el-row>

  <el-row class="mw-1200" justify="space-around">
    <el-col
      :xs="{ span: 22, offset: 0 }"
      :sm="{ span: 22, offset: 0 }"
      :md="{ span: 22, offset: 0 }"
      :lg="{ span: 22, offset: 0 }"
      :xl="{ span: 22, offset: 0 }"
      class="overflow"
    >
      <img src="/img/section-four-image.png" class="section-four-image" />
    </el-col>
  </el-row>

  <el-row class="mw-1200 gilroy-medium fine-print smallText mb-15 ttext-center">
    <el-col
      :xs="{ span: 20, offset: 2 }"
      :sm="{ span: 24, offset: 0 }"
      :md="{ span: 18, offset: 6 }"
      :lg="{ span: 18, offset: 6 }"
      :xl="{ span: 18, offset: 6 }"
    >
* Fans can subscribe and receive monthly live NFT drops directly to their wallets, they will need to unpack the NFT to see which rarity they get.    </el-col>
    <el-col
      :xs="{ span: 20, offset: 2 }"
      :sm="{ span: 20, offset: 2 }"
      :md="{ span: 18, offset: 6 }"
      :lg="{ span: 18, offset: 6 }"
      :xl="{ span: 18, offset: 6 }"
    >
     ** Staking based earning models enable view based earning for streamers without the need for ads.
    </el-col>
    <el-col
      :xs="{ span: 20, offset: 2 }"
      :sm="{ span: 20, offset: 2 }"
      :md="{ span: 18, offset: 6 }"
      :lg="{ span: 18, offset: 6 }"
      :xl="{ span: 18, offset: 6 }"
    >
      *** The online streaming/entertainment market is estimated to reach $110 billion by 2025.
    </el-col>
  </el-row>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="css" scoped>
.section-four-top-side {
  margin-bottom: 5%;
}

.section-four-image {
  width: 100%;
}

.fine-print {
  font-size: 13px;
}

.fine-print .el-col:not(:last-of-type) {
  margin-bottom: 1%;
}

@media only screen and (min-width: 991px) {
  .paragraph-text-24{
        margin-top: auto;
    margin-bottom: auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 991px) {
  .section-four-left-side {
    margin-bottom: 5%;
  }

  .section-four-right-side {
    margin-bottom: 5%;
  }

  .section-four-image {
    width: 100%;
  }

  .fine-print {
    margin-left: 0;
    margin-top: 5%;
    font-size: 12px;
    line-height: 13px;
  }

  .fine-print .el-col:not(:last-of-type) {
    margin-bottom: 2%;
  }

  .tmt-m6h {
    margin-top: -6.5%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 767px) {
  .smallText {
    margin-bottom: 50%;
  }
  #nfts {
    padding-top: 50px;
  }
  .section-four-right-side {
    margin-bottom: 10%;
  }

  .section-four-image {
    width: 260%;
  }

  .fine-print {
    font-size: 12px;
    line-height: 17px;
  }

  .fine-print .el-col:not(:last-of-type) {
    margin-bottom: 3%;
  }
  .section-four-image {
    margin-top: 5%;
  }

  .overflow {
    overflow: scroll;
  }

  .tmt-m6h {
    margin-top: 0%;
  }
}
</style>