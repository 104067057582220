<template>
  <el-row class="mw-1200">
    <el-col
      :xs="{ span: 20, offset: 2 }"
      :sm="{ span: 12, offset: 1 }"
      :md="{ span: 9, offset: 2 }"
      :lg="{ span: 9, offset: 2 }"
      :xl="{ span: 9, offset: 0 }"
    >
      <el-row class="mtext-center">
        <el-col class="page-title-80 mb-15"
          >First
          <span class="pink-text">Token</span>
        </el-col>
        <el-col class="paragraph-title-34 mb-3">Staking & Farming</el-col>
        <el-col :sm="{ span: 23, offset: 0}" class="paragraph-text-21 mb-15 tpadding">
          Participate in Creator staking & NFT farming using the FIRST token.
          Earn crypto by directly joining forces with artists & creators.
        </el-col>
        <el-col class="paragraph-title-34 mb-3">Premium Access</el-col>
        <el-col :sm="{ span: 17, offset: 0}" class="paragraph-text-21 mb-15">
          Use the FIRST token to get access to premium live streams or buy NFTs across multiple chains.
        </el-col>
        <el-col class="paragraph-title-34 mb-3">Challenges & Giveaways</el-col>
        <el-col :sm="{ span: 20, offset: 0}" class="paragraph-text-21 mb-15">
          FIRST token holders can enjoy a discount on NFT Access Pass and marketplace
          fees or earn FIRST tokens when completing challenges.
        </el-col>
      </el-row>
    </el-col>
    <el-col
      :xs="{ span: 20, offset: 2 }"
      :sm="{ span: 10, offset: 0 }"
      :md="{ span: 9, offset: 3 }"
      :lg="{ span: 9, offset: 2 }"
      :xl="{ span: 10, offset: 5 }"
      class="text-center"
    >
      <img class="nine-image" src="/img/section-nine-image1.png" />
      <a href="/downloads/FIRST__Tokenomics.pdf" target="_blank">
        <el-button class="btn-tokenomics gilroy-bold">
          View Tokenomics
        </el-button>
      </a>
    </el-col>
  </el-row>
</template>

<style lang="css" scoped>
.first-coin-title {
  font-size: 5em;
}

.nine-image {
  width: 100%;
  margin-left: 3%;
}

.btn-tokenomics {
  background-color: #191234;
  border-radius: 26px;
  border: none;
  color: #fff;
  width: 200px;
  height: 41px;
  padding: 10px 35px;
  /* margin-left: -5%; */
}

.btn-tokenomics:hover,
.btn-tokenomics:active,
.btn-tokenomics:focus {
  background-color: #191234;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .first-coin-title {
    font-size: 4em;
  }
}

@media only screen and (max-width: 991px) {
  .first-coin-title {
    font-size: 3.5em;
  }

  .tpadding{
    padding-right: 3%;
  }
}

@media only screen and (max-width: 767px) {
  .first-coin-title {
    font-size: 3em;
  }

  .tpadding{
    padding-right: 0%;
  }
}
</style>