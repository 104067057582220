<template>
  <el-row class="mw-1200 align-center">
    <el-col
      :xs="{ span: 22, offset: 1 }"
      :sm="{ span: 14, offset: 1 }"
      :md="{ span: 12, offset: 2 }"
      :lg="{ span: 13, offset: 1 }"
      :xl="{ span: 13, offset: 0 }"
    >
      <el-row class="gilroy-bold align-center mb-2">
        <el-col class="page-title-82">The First NFT</el-col>
        <el-col class="page-title-82 pink-text">Live Streaming</el-col>
        <el-col class="page-title-82 mb-5">Platform</el-col>
        <el-col class="paragraph-text-24 mb-5" style="line-height: 35px"
          >The next-generation NFT live streaming platform.</el-col
        >
        <el-col
          :xs="{ span: 10 }"
          :sm="{ span: 12 }"
          :md="{ span: 9 }"
          :lg="{ span: 7 }"
          :xl="{ span: 7 }"
        >
          <a href="/downloads/FIRST_Pitchdeck.pdf" target="_blank">
            <el-button class="btn-pitchdeck gilroy-bold">Pitchdeck</el-button>
          </a>
        </el-col>
      </el-row>
    </el-col>
    <el-col
      :xs="{ span: 22, offset: 0 }"
      :sm="{ span: 9, offset: 0 }"
      :md="{ span: 8, offset: 1 }"
      :lg="{ span: 9, offset: 1 }"
      :xl="{ span: 10, offset: 1 }"
    >
      <img
        src="/img/section-one-image.png"
        class="section-one-right-image hidden-tablet"
      />
      <img
        src="/img/section-one-image-tablet.png"
        class="section-one-right-image shown-tablet"
      />
    </el-col>
  </el-row>
</template>

<style lang="css" scoped>
.whitepaper-soon-text {
  color: #c6c1d7;
}

.section-one-right-image {
  width: 100%;
}

.btn-pitchdeck {
  background-color: #191234;
  border: 3px solid #191234;
  border-radius: 26px;
  font-size: 19px;
  color: #fff;
  width: 150px;
}

.btn-pitchdeck:hover,
.btn-pitchdeck:active,
.btn-pitchdeck:focus {
  background-color: #191234;
  color: #fff;
  cursor: pointer;
  border: 3px solid #191234;
}

.btn-pitchdeck:disabled {
  background-color: #bbb8c1;
  color: #fff;
}

.btn-whitepaper {
  background-color: transparent;
  border: 3px solid #191234;
  border-radius: 26px;
  font-size: 19px;
  color: #191234;
  width: 150px;
  opacity: 0.25;
}

.btn-whitepaper:hover,
.btn-whitepaper:active,
.btn-whitepaper:focus {
  background-color: transparent;
  color: #191234;
  cursor: not-allowed;
  border: 3px solid #191234;
}

.btn-whitepaper:disabled {
  background-color: transparent;
  color: #191234;
  opacity: 0.25;
}

.hidden-tablet {
  display: flex;
}

.shown-tablet {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .whitepaper-soon-text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .btn-pitchdeck,
  .btn-whitepaper {
    font-size: 15px;
    width: 90%;
    height: 34px;
    line-height: 10px;
    box-sizing: border-box;
    padding: 0;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .hidden-tablet {
    display: none;
  }

  .shown-tablet {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .whitepaper-soon-text {
    font-size: 14px;
  }

  .hidden-tablet {
    display: flex;
  }

  .shown-tablet {
    display: none;
  }
}
</style>