<template>
  <el-row class="mw-1200 hidden-sm-and-up section-two-text mb-10">
    <el-col
      class="gilroy-bold paragraph-title-28 fz-25 mb-3"
      :xs="{ span: 22, offset: 1 }"
      >You've never seen streaming like this before!</el-col
    >
  </el-row>
  
  <el-row class="mw-1200 mb-8 hidden-sm-and-up">
    <el-col
      :xs="{ span: 22, offset: 2 }"
    >
      <img src="/img/section-two-image-mobile.png" class="section-two-image" />
    </el-col>
  </el-row>
  
  <el-row class="mw-1200 mb-8 hidden-767-and-down" justify="space-around">
    <el-col
      :xs="{ span: 22 }"
      :sm="{ span: 20 }"
      :md="{ span: 20 }"
      :lg="{ span: 24 }"
      :xl="{ span: 24 }"
    >
      <img src="/img/section-two-image.png" class="section-two-image" />
    </el-col>
  </el-row>
  <el-row class="mw-1200 hidden-767-and-down section-two-text">
    <el-col
      class="gilroy-bold paragraph-title-28 mb-3"
      :xs="{ span: 22, offset: 1 }"
      >You've never seen streaming like this before!</el-col
    >
  </el-row>
  <el-row
    class="mw-1200 section-two-text paragraph-text-26 tmb-25"
    justify="space-around"
  >
    <el-col
      :xs="{ span: 18 }"
      :sm="{ span: 21 }"
      :md="{ span: 14 }"
      :lg="{ span: 13 }"
      :xl="{ span: 13 }"
      class="gilroy-medium"
    >
      FIRST lets streamers, artists and content creators monetiz their streaming content with a new standard based on NFTs and web 3.0 streaming experiences.
    </el-col>
  </el-row>
</template>

<style lang="css" scoped>
.section-two-image {
  width: 100%;
  border-radius: 42px;
  box-shadow: -20px 20px 70px -20px rgba(185, 177, 209, 1);
  -webkit-box-shadow: -20px 20px 70px -20px rgba(185, 177, 209, 1);
  -moz-box-shadow: -20px 20px 70px -20px rgba(185, 177, 209, 1);
}

.section-two-text {
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .section-two-image {
    border-radius: 24px;
  }
}

@media only screen and (max-width: 991px) {
  .tmb-25{
    margin-bottom: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .section-two-image {
    border-radius: 20px 0 0 20px;
  }
  .section-two-text{
    margin-top: 15%;
  }
  .hidden-767-and-down{
    display:none;
  }
}
</style>