<template>
  <div id="about" class="mw-1200">
    <el-row class="page-title-70 text-center mb-5">
      <el-col :xs="{ span: 16, offset: 4 }">
        <span class="pink-text">&bull; Live</span> Stream & NFTs.
      </el-col>
      <el-col class="perfect-pair-mobile"> The perfect pair. </el-col>
    </el-row>
    <el-row class="align-center mmb-15">
      <el-col
        :xs="{ span: 22, offset: 1 }"
        :sm="{ span: 10, offset: 1 }"
        :md="{ span: 9, offset: 2 }"
        :lg="{ span: 10, offset: 1 }"
        :xl="{ span: 12, offset: 0 }"
      >
        <img src="/img/section-seven-image1.png" class="seven-image" />
      </el-col>
      <el-col
        :xs="{ span: 20, offset: 2 }"
        :sm="{ span: 12, offset: 0 }"
        :md="{ span: 9, offset: 2 }"
        :lg="{ span: 9, offset: 2 }"
        :xl="{ span: 9, offset: 2 }"
      >
        <el-row>
          <el-col class="paragraph-title-34 mb-3 mtext-center"
            >NFT Subscription Pass</el-col
          >
          <el-col class="paragraph-text-21 seven-text-mb-10 mtext-center">
            Subscribe to receive instant content and NFT updates, view favorites and receive discounts on NFT Access Passes. 
            <span class="gilroy-bold"
              >Fans can also receive tier level rarity NFT drops.
            </span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="align-center hidden-767-and-down">
      <el-col
        :xs="{ span: 22, offset: 1 }"
        :sm="{ span: 12, offset: 1 }"
        :md="{ span: 9, offset: 1 }"
        :lg="{ span: 9, offset: 1 }"
        :xl="{ span: 9, offset: 2 }"
      >
        <el-row class="feature-second-text">
          <el-col class="paragraph-title-34 mb-3">
            NFT Access Pass
          </el-col>
          <el-col class="paragraph-text-21">
            Fans can purchase basic or VIP NFT Access Passes to gain entry to unique live events and 
            <span class="gilroy-bold">a chance to unlock rare NFTs.</span>
          </el-col>
        </el-row>
      </el-col>
      <el-col
        :xs="{ span: 22, offset: 1 }"
        :sm="{ span: 10, offset: 0 }"
        :md="{ span: 10, offset: 1 }"
        :lg="{ span: 10, offset: 1 }"
        :xl="{ span: 12, offset: 1 }"
      >
        <img src="/img/section-seven-image2.png" class="seven-image pl-8" />
      </el-col>
    </el-row>

    <el-row class="align-center hidden-767-and-up mmb-15">
      <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 14, offset: 5 }">
        <img src="/img/section-seven-image2.png" class="seven-image" />
      </el-col>
      <el-col :xs="{ span: 20, offset: 2 }" :sm="{ span: 18, offset: 3 }">
        <el-row>
          <el-col class="paragraph-title-34 mb-3 mtext-center"
            >NFT Access Pass</el-col
          >
          <el-col class="paragraph-text-21 seven-text-mb-10 mtext-center">
            Fans can purchase basic or VIP NFT Access Passes to gain entry to unique live events and 
            <span class="gilroy-bold">a chance to unlock rare NFTs.</span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="align-center">
      <el-col
        :xs="{ span: 22, offset: 1 }"
        :sm="{ span: 10, offset: 1 }"
        :md="{ span: 9, offset: 2 }"
        :lg="{ span: 10, offset: 1 }"
        :xl="{ span: 12, offset: 0 }"
      >
        <img src="/img/section-seven-image3.png" class="seven-image" />
      </el-col>
      <el-col
        :xs="{ span: 20, offset: 2 }"
        :sm="{ span: 12, offset: 0 }"
        :md="{ span: 9, offset: 2 }"
        :lg="{ span: 9, offset: 2 }"
        :xl="{ span: 9, offset: 2 }"
      >
        <el-row class="mmb-15">
          <el-col class="paragraph-title-34 mb-3 mtext-center"
            >NFT marketplaces</el-col
          >
          <el-col class="paragraph-text-21 mb-8 mtext-center">
            FIRST offers users premium content from your favorite streamers, gamers, artists and more all fueled by NFTs. Buy & trade NFTs, 
            <span class="gilroy-bold"
              >show them off in 3D NFT Galleries
            </span>
            or farm NFTs to earn crypto together.
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="css" scoped>
.seven-title {
  font-size: 4.5em;
  text-align: center;
}

.seven-image {
  width: 100%;
}

.feature-title {
  font-size: 2.15em;
  margin-bottom: 5%;
}

.feature-text {
  font-size: 1.3em;
  line-height: 2.2em;
}

.feature-second-text {
  text-align: right;
}

.pl-8 {
  padding-left: 8%;
}

.hidden-767-and-down {
  display: flex;
}

.hidden-767-and-up {
  display: none;
}

@media only screen and (max-width: 1919px) {
  .pl-8 {
    padding-left: 9%;
  }
}

@media only screen and (max-width: 1550px) {
  .pl-8 {
    padding-left: 11%;
  }
}

@media only screen and (max-width: 1199px) {
  .pl-8 {
    padding-left: 12%;
  }
}

@media only screen and (max-width: 991px) {
  .seven-text-mb-10 {
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .perfect-pair-mobile {
    margin-top: 6%;
    font-size: 30px;
    line-height: 50px;
  }
  .paragraph-title-34 {
    margin-bottom: 5%;
  }
  .hidden-767 {
    display: none;
  }

  .hidden-767-and-down {
    display: none;
  }

  .hidden-767-and-up {
    display: flex;
  }
}
</style>